/* eslint-disable */
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// Configuration object constructed.
const config = {
    auth: {
        clientId: process.env.REACT_APP_MS_CLIENT_ID_OS,
        authority: process.env.REACT_APP_MS_AUTHORITY,
        redirectUri: process.env.REACT_APP_MS_REDIRECT_OS
    }
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

createRoot(document.getElementById('root')).render(
  <MsalProvider instance={publicClientApplication}>
    <Provider store={store}>
          <App />
    </Provider>
  </ MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
