/* eslint-disable */
import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";

const LoginPage = React.lazy(() => import('./pages/login/login'))

const authGroup = process.env.REACT_APP_MS_AUTH_GROUP_OS;

const MsalGroup = (props) => {
  const { instance } = useMsal();
  const { children } = props;
  const [ authorized, setAuthorized] = useState(false);
  const account = instance.getAllAccounts().find(function (acc) {
    return acc.tenantId == process.env.REACT_APP_MS_TENANT_ID
  }
  )
  //instance.getAllAccounts()[0];

  React.useEffect(() => {

   if (
    !account ||
    !account.idTokenClaims ||
    !account.idTokenClaims.groups
  ) {
    setAuthorized(false);
  } else {
    setAuthorized(
      account.idTokenClaims.groups.includes(authGroup)
    );
  }
  }, [instance]);


  return (
    <>
        {(authorized && <>{children}</>) ||
        (<LoginPage />)}
    </>
  );
};

export default MsalGroup;
