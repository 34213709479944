/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import MsalGroup from "./MsalGroup";
import { init as initApm } from '@elastic/apm-rum'


import './scss/style.scss'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const apm = initApm({
      serviceName: 'os-frontend-app',
      serverUrl: 'https://86e8f8a79a114f299321591f7d31ef88.apm.westeurope.azure.elastic-cloud.com:443',
      serviceVersion: '',
      environment: process.env.NODE_ENV
})

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const LoginPage = React.lazy(() => import('./pages/login/login'))
const WaitLogin = React.lazy(() => import('./pages/login/wait'))


class App extends Component {
  render() {
    return (
    <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={LoginPage}
        loadingComponent={WaitLogin}>
      <MsalGroup>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      </MsalGroup>
    </MsalAuthenticationTemplate>
    )
  }
}

export default App
